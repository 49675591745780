<template>
	<div class="notice_popup human">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit" style="margin-left: 0;"><img style="vertical-align: middle;" src="/images/caution.png" alt="알림"/>죄송합니다!</div>
		<div class="member" style="line-height: 30px;">해당 정보는<br/>유료 서비스에 제공됩니다!</div>
		<div class="select-btn">
			<div class="btn" style="background-color: #75A5BF;" @click="$emit('close')">닫기</div>
		</div>
	</div>
</template>
<script>
export default {
	data() { return { } },
    methods: { }
}
</script>